<template>
  <div class="body_wrap">

<!-- backtotop - start -->
<div class="backtotop">
  <a href="#" class="scroll">
    <i class="far fa-arrow-up"></i>
  </a>
</div>
<!-- backtotop - end -->

<!-- preloader - start -->
<!-- <div id="preloader"></div> -->
<!-- preloader - end -->

<!-- header_section - start
================================================== -->
<header class="header_section">
        <div class="header_middle">
          <div class="container">
            <div class="row align-items-center">
              <div class="col col-lg-3 col-5">
                <div class="brand_logo">
                  <a class="brand_link" href="/">
                    <img src="./assets/logo.png">
                  </a>
                </div>
              </div>
              <div class="col col-lg-6 col-2">
                <nav class="main_menu navbar navbar-expand-lg">
                  <div class="main_menu_inner collapse navbar-collapse justify-content-center" id="main_menu_dropdown">
                    <ul class="main_menu_list ul_li">
                      <!-- <li class="active">
                        <a href="/">Home</a>
                      </li> -->
                      <!-- <li>
                        <a href="/register">register</a>
                      </li> -->
                      <!-- <li><a href="/register">Sign In</a></li>
                      <li><a href="/cart">Shopping Cart</a></li> -->
                      <li><a href="/#1">HOT ITEMS</a></li>
                      <li><a href="/#2">NEW ARRIVALS</a></li>
                      <li><a href="/#3">BEST SELLER</a></li>
                    </ul>
                  </div>
                </nav>
              </div>

              <div class="col col-lg-3 col-5">
                <ul class="header_icons_group ul_li_right">
                  <li>
                    <button class="mobile_menu_btn navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_menu_dropdown" aria-controls="main_menu_dropdown" aria-expanded="false" aria-label="Toggle navigation">
                      <i class="fal fa-bars"></i>
                    </button>
                  </li>
                  <li><a href="/cart"><i class="fa-thin fa-cart-shopping"></i></a></li>
                  <li><a href="/account"><i class="fa-regular fa-user"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="header_bottom">
          <div class="container">
            <div class="row align-items-center" style="padding:10px 0px;">
              <div class="col col-md-10 col-10">
                <div class="allcategories_dropdown">
                  <h2 class="title_text" style="color:#fff;"><i class="fa-duotone fa-fire"></i> Technology e-commerce, worry-free shopping!</h2>
                </div>
              </div>

              <div class="col col-md-2 col-2">
                <!-- <a href="/cart">
                <button type="button" class="cart_btn">
                  <span class="cart_icon">
                    <i class="icon icon-ShoppingCart"></i>
                  </span>
                </button>
              </a> -->
              </div>
            </div>
          </div>
        </div>
      </header>
<!-- header_section - end
================================================== -->

<!-- main body - start
================================================== -->
<main>

  <!-- sidebar cart - start
  ================================================== -->
  <div class="sidebar-menu-wrapper">
    <div class="cart_sidebar">
      <button type="button" class="close_btn"><i class="fal fa-times"></i></button>

      <ul class="cart_items_list ul_li_block mb_30 clearfix">
        <li>
          <div class="item_image">
            <img src="assets/images/cart/cart_img_1.jpg" alt="Getyootech - Gadgets Ecommerce Site Template">
          </div>
          <div class="item_content">
            <h4 class="item_title">Yellow Blouse</h4>
            <span class="item_price">MXN$30.00</span>
          </div>
          <button type="button" class="remove_btn"><i class="fal fa-trash-alt"></i></button>
        </li>
        <li>
          <div class="item_image">
            <img src="assets/images/cart/cart_img_2.jpg" alt="Getyootech - Gadgets Ecommerce Site Template">
          </div>
          <div class="item_content">
            <h4 class="item_title">Yellow Blouse</h4>
            <span class="item_price">MXN$30.00</span>
          </div>
          <button type="button" class="remove_btn"><i class="fal fa-trash-alt"></i></button>
        </li>
        <li>
          <div class="item_image">
            <img src="assets/images/cart/cart_img_3.jpg" alt="Getyootech - Gadgets Ecommerce Site Template">
          </div>
          <div class="item_content">
            <h4 class="item_title">Yellow Blouse</h4>
            <span class="item_price">MXN$30.00</span>
          </div>
          <button type="button" class="remove_btn"><i class="fal fa-trash-alt"></i></button>
        </li>
      </ul>

      <ul class="total_price ul_li_block mb_30 clearfix">
        <li>
          <span>Subtotal:</span>
          <span>MXN$90</span>
        </li>
        <li>
          <span>Vat 5%:</span>
          <span>MXN$4.5</span>
        </li>
        <li>
          <span>Discount 20%:</span>
          <span>- MXN$18.9</span>
        </li>
        <li>
          <span>Total:</span>
          <span>MXN$75.6</span>
        </li>
      </ul>

      <ul class="btns_group ul_li_block clearfix">
        <li><a class="btn btn_primary" href="cart.html">View Cart</a></li>
        <li><a class="btn btn_secondary" href="checkout.html">Checkout</a></li>
      </ul>
    </div>

    <div class="cart_overlay"></div>
  </div>
  <!-- sidebar cart - end
  ================================================== -->

  <!-- breadcrumb_section - start
  ================================================== -->
  <div class="breadcrumb_section">
    <div class="container">
      <ul class="breadcrumb_nav ul_li">
        <li><a href="/">Home</a></li>
        <li>Check Out</li>
      </ul>
    </div>
  </div>
  <!-- breadcrumb_section - end
  ================================================== -->

  <!-- checkout_section - start
  ================================================== -->
  <section class="checkout_section section_space">
    <div class="container">
      <form action="#">

        <div class="checkout_widget">
          <h3 class="checkout_widget_title">Your Order</h3>
          <div class="cart_table checkout_table">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-center">PRODUCT</th>
                  <th class="text-center">PRICE</th>
                  <th class="text-center">QUANTITY</th>
                  <th class="text-center">TOTLA</th>
                </tr>
              </thead>
              <tbody v-if="apiFrontOrderDetailData.orderInfoList">
                <tr v-for="(item, index) in apiFrontOrderDetailData.orderInfoList" :key="index" style="border-bottom: 1px solid #ededed;">
                  <td>
                    <div class="cart_product" style="justify-content: center;">
                      <img style="margin:auto;" :src="'assets/images/' + item.image" alt="Getyootech - Gadgets Ecommerce Site Template">
                      <!-- <h3>{{ item.storeName }}</h3> -->
                    </div>
                  </td>
                  <td class="text-center"><span class="price_text">MXN${{ item.price }}</span></td>
                  <td class="text-center"><strong class="quantity_count">{{ item.cartNum }}</strong></td>
                  <td class="text-center"><span class="price_text">MXN${{ item.price }}</span></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td class="text-center"><strong>Cart Subtotal</strong></td>
                  <td class="text-center"><strong>MXN${{ apiFrontOrderDetailData.totalPrice }}</strong></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td class="text-center"><strong>Shipping and Handling</strong></td>
                  <td class="text-center"><strong class="free_text">Free</strong></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td class="text-center"><strong>Order Total</strong></td>
                  <td class="text-center"><strong class="total_text">MXN${{ apiFrontOrderDetailData.totalPrice }}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>
      <div class="checkout_payment_method">
          <ul class="ul_li_block">
            <li>
              
            </li>

            <li>
              <div class="radio_item">
                <input id="paypal_transfer" v-model="wowPay" type="radio" name="payment_method">
                <label for="paypal_transfer">Clabe</label>
              </div>
              <div class="payment_card">
                <img style="height: 40px;" src="./assets/clabe.png" alt="Getyootech - Gadgets Ecommerce Site Template">
              </div>
            </li>
            <li>
              <div class="radio_item">
                <input id="paypal_transfer" v-model="yue" type="radio" name="payment_method">
                <label for="paypal_transfer">Balance</label>
              </div>
              <div class="payment_card">
                <!-- <img src="assets/images/payments_icon_3.png" alt="Getyootech - Gadgets Ecommerce Site Template"> -->
              </div>
            </li>
          </ul>
          <!-- <div class="load_more">
            <button @click="clickOrder()" type="submit" class="btn btn_primary w-100">Place Order</button>
          </div> -->
          <div class="load_more">
            <button @click="fukuan()" type="submit" class="btn btn_primary w-100">Place Order</button>
          </div>
        </div>
    </div>
  </section>
  <!-- checkout_section - end
  ================================================== -->

  <!-- newsletter_section - start
  ================================================== -->
  <section class="newsletter_section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col col-lg-6">
          <h2 class="newsletter_title text-white">
            <i class="icofont-paper-plane"></i>
            Sign Up for Newsletter
          </h2>
        </div>
        <div class="col col-lg-6">
          <form action="#!">
            <div class="newsletter_form">
              <input type="email" name="email" placeholder="Enter your email address">
              <button type="submit" class="btn btn_danger">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- newsletter_section - end
  ================================================== -->

</main>
<!-- main body - end
================================================== -->

<!-- footer_section - start
================================================== -->
<footer class="footer_section">
        <div class="footer_widget_area">
          <div class="container">
            <div class="row">
              <div class="col col-lg-2 col-md-3 col-sm-6">
                <div class="footer_widget footer_useful_links">
                  <h3 class="footer_widget_title text-uppercase">Quick Links</h3>
                  <ul class="ul_li_block">
                    <li><a href="/register">Sign In</a></li>
                    <!-- <li><a href="/register">Sign Up</a></li> -->
                  </ul>
                </div>
              </div>

              <div class="col col-lg-2 col-md-3 col-sm-6">
                <div class="footer_widget footer_useful_links">
                  <h3 class="footer_widget_title text-uppercase">Custom area</h3>
                  <ul class="ul_li_block">
                    <li><a href="/account">My Account</a></li>
                    <li><a href="/cart">My Cart</a></li>
                  </ul>
                </div>
              </div>

              <div class="col col-lg-8 col-md-6 col-sm-12">
                <div class="footer_widget footer_useful_links">
                  <h3 class="footer_widget_title text-uppercase">contact us</h3>
                  <ul class="ul_li_block">
                    <li>Company Name： INFORMATICA GH SOLUCIONES, S.A. DE C.V.</li>
                    <li>E-mail: hungrybulo@outlook.com</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
<!-- footer_section - end
================================================== -->

</div>
</template>

<script>
import {
  apiFrontCartList, apiFrontOrderPreOrder, apiFrontOrderComputedPrice, apiFrontOrderLoadPre, apiFrontOrderCreate, apiFrontPayPayment, apiFrontOrderDetail
} from '@/api/goods'
export default {
  data() {
    return {
      pageData: {},
      page: {
        isValid: true,
        limit: 100,
        page: 1
      },
      dingdanData: {},
      province: '',
      city: '',
      district: '',
      Address: '',
      dingdanhao: '',
      orderNoData: '',
      apiFrontOrderDetailData: {},
      wowPay: false,
      yue: false
    }
  },
  mounted() {
    // this.gouwuche()
    var aa = localStorage.getItem('orderNo')
    apiFrontOrderDetail(aa).then(res => {
      console.log(res);
      this.apiFrontOrderDetailData = res.data
    })
  },
  methods: {
    gouwuche() {
      var data = {
        preOrderType: 'shoppingCart',
        orderDetails: []
      }
      apiFrontCartList(this.page).then(res => {
        console.log(res);
        this.pageData = res.data
        for (let index = 0; index < this.pageData.list.length; index++) {
          var aa = {
            shoppingCartId: this.pageData.list[index].id
          }
          data.orderDetails.push(aa)
        }
        apiFrontOrderPreOrder(data).then(ress => {
          console.log(ress)
          var danhao = {
            shippingType: 1,
            preOrderNo: ress.data.preOrderNo,
            useIntegral: false
          }
          this.dingdanhao = ress.data.preOrderNo
          apiFrontOrderLoadPre(ress.data.preOrderNo).then(dingdan => {
            console.log(dingdan);
            this.dingdanData = dingdan.data
          })
          apiFrontOrderComputedPrice(danhao).then(resss => {
            console.log(resss);
            this.amountData = resss.data
          })
        })
      })
      
    },
    clickOrder() {
      var data = {
        inputAddress: this.province + this.city + this.district + this.Address,
        payChannel: 'wowpayCheckout',
        payType: 'wowpay',
        preOrderNo: this.dingdanhao,
        shippingType: 1,
        useIntegral: false,
        mark: ''
      }
      apiFrontOrderCreate(data).then(res => {
        console.log(res);
        this.orderNoData = res.data.orderNo
        localStorage.setItem('orderNo', res.data.orderNo)
      })
    },
    fukuan() {
      console.log(this.wowPay);
      console.log(this.yue);
      var data = {
        orderNo: localStorage.getItem('orderNo'),
        payType: 'wowpay',
        payChannel: '99'
      }
      if (this.wowPay === null) {
        data.payType = 'wowpay'
        this.yue = false
      } else {
        this.wowPay = false
        data.payType = 'yue'
      }
      apiFrontPayPayment(data).then(res => {
        if (res.data.wowPayCheckout) {
          window.open(res.data.wowPayCheckout, '_blank');
        } else {
          this.$router.push('/account')
          this.$message.success('payment approval')
        }
      }).catch((err) => {
        this.$message.error(err)
      })
    }
  }
}
</script>

<style>

</style>